import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    List,
    ListItem,
    ListItemText,
    Divider,
    CardHeader,
    CardContent,
    CircularProgress,
    Chip
} from '@material-ui/core';

import { formatPhone } from '../util/util';
import { getTripTicketUrl } from '../middleware/api';

import {
    getSpecificUser,
    createUserNote,
    hideUserNote,
    unhideUserNote
} from "../actions/userActions";
import {
    getOrderDetailsWithId,
    GET_ORDER_DETAILS_WITH_ID_FAILURE
} from '../actions/orderActions';

import { getUserNotesFromProps, getUsersByName } from "../selectors/userSelectors";
import { getUserWithDSPRManagersAndDSPRDrivers } from "../selectors/dsprManagerSelectors"
import { getUserIdDocumentFromPropsWithOrder, getUserMedicalRecommendations } from '../selectors/userDocumentsSelector';
import {
    getCurrentDriverInventoryPeriodForDriverFromProps,
    getDSPRDriverWithUserFromProps,
    getOnCallDriversForDSPR
} from '../selectors/dsprDriverSelector';

import {
    User,
    Address,
    Order,
    IdDocument,
    State,
    MedicalRecommendation,
    DsprDriver,
    DspProduct,
    DsprDriverInventoryItem,
    OrderScan, DSPR
} from '../store/reduxStoreState';
import { parseDate } from '../util/util';

import OrderDetailListItem from '../components/OrderDetailListItem';
import UserNotes from '../components/UserNotes';
import ModifyOrderForm from './ModifyOrder';

import './orderDetailStyles.scss';
import { Link } from 'react-router-dom';
import {
    getAlreadyScannedForOrder
} from "../actions/scanActions";
import { getOrderScansForOrderFromProps } from "../selectors/scanSelectors";
import {
    getCurrentFullMenuDriverIdForDSPRFromProps,
    getDSPRFromProps
} from "../selectors/dsprSelectors";
import { OnCallDrivers } from "./DSPRPage";
import { AllotmentButtons } from '../components/OrderStatusChanges/Allotment/AllotmentButtons';
import { ScanProductButtons } from '../components/OrderStatusChanges/ScanProductButtons';
import { AssignDriverButtons } from "../components/OrderStatusChanges/AssignDriverButtons";
import { QueueButtons } from "../components/OrderStatusChanges/QueueButtons";
import { UserNotifiedAllotmentTime } from "../components/OrderStatusChanges/Allotment/UserNotifiedAllotmentTime";
import { InRouteButtons } from "../components/OrderStatusChanges/InRouteButtons";
import { CancelOrderButtons } from '../components/OrderStatusChanges/CancelOrderButtons';
import { UncancelOrderButton } from "../components/OrderStatusChanges/UncancelOrderButton";
import { UncompleteOrderButton } from "../components/OrderStatusChanges/UncompleteOrderButton";
import { InProcessButton } from "../components/OrderStatusChanges/InProcessButton";
import { CompleteOrderButton } from "../components/OrderStatusChanges/CompleteOrderButton";
import { PackageButton } from "../components/OrderStatusChanges/PackageButton";
import { green } from '@material-ui/core/colors';

type OrderWithDetails = (Omit<Omit<Order, 'address'>, 'user'> & { address: Address, user: User });
type AvailableInventoryItem = Omit<DsprDriverInventoryItem, 'product'> & { product: DspProduct };
type DsprDriversWithUserAndOrders = (Omit<DsprDriver, 'user'> & { user: User })[];
interface OrderWithDetailsAndPricesProps {
    order: OrderWithDetails;
    user: User;
    address: Address;
    dsprDriverId?: number;
    dsprManagerId?: number;
    hideMeta?: boolean;
    hideNote?: boolean;
    hideSpecialInstructions?: boolean;
    hideDriver?: boolean;
    showTripTicketOnly?: boolean;
    hideAdditionalOrderFunctionality?: boolean;
    cancelOrderHandler?: (orderId: number) => any;
    closeModal?: () => void;
    modifyOrder?: {
        show: boolean;
        dsprDrivers: DsprDriversWithUserAndOrders;
        modifyOrder: (orderId: number, orderDetails: any, newDriverId: number, transferInventoryOnDriverchange: boolean, couponCodes?: string[]) => Promise<string>;
        availableInventory: AvailableInventoryItem[];
        getDSPRDriver: (driverId: number) => Promise<boolean>;
        calculateOrderTotal: (order: any) => Promise<string | Order>;
        getProductWithId: (productId: number) => Promise<string | DspProduct>;
    };
}

const OrderWithDetailsAndPrices: React.FC<OrderWithDetailsAndPricesProps> = props => {
    const { order: orderFromProps, user, address, dsprDriverId, hideMeta = false, showTripTicketOnly, hideNote = false, hideDriver = false, modifyOrder = {}, dsprManagerId, hideSpecialInstructions = false, hideAdditionalOrderFunctionality = false, cancelOrderHandler,
        closeModal
    } = props;
    const { id: orderId, coupons, dspr: dsprId } = orderFromProps;
    const { show, ...modifyOrderProps } = modifyOrder as any;
    const onCallDrivers = useSelector<State, OnCallDrivers>(state => getOnCallDriversForDSPR(state, { dsprId }), shallowEqual);
    const [isLoading, setIsLoading] = useState(true);
    const [order, setOrder] = useState<OrderWithDetails>(orderFromProps);
    const [error, setError] = useState('');
    const [extraOrderFunctionalityError, setExtraOrderFunctionalityError] = useState("");
    const [isManagerForDSPR, setIsManagerForDSPR] = useState<boolean>(false);
    const [isDriverForOrder, setIsDriverForOrder] = useState<boolean>(false);
    const [disableExtraButtons, setDisableButtons] = useState<boolean>(false);
    const orderDetails = order.orderDetails;
    const mounted = useRef(true);
    const users = useSelector<State, { value: number, text: string }[]>(getUsersByName, shallowEqual);

    const dsprDriverIdFromOrder = order && order.dsprDriver;

    const dspr = useSelector<State, DSPR>(
        (state) => getDSPRFromProps(state, { dsprId }),
        shallowEqual
    );

    const inventoryDriverId = dspr && dspr.allowDriverKits !== undefined && !dspr.allowDriverKits ? dspr.currentFullMenuDriver : dsprDriverIdFromOrder

    const dispatch = useDispatch();
    const userNotes = useSelector<State, any[]>(state => getUserNotesFromProps(state, { userId: user.id }), shallowEqual);
    const idDocument = useSelector<State, IdDocument>(state => getUserIdDocumentFromPropsWithOrder(state, { userId: user.id, order: order }), shallowEqual);
    const medicalRecommendations = useSelector<State, { [key: number]: MedicalRecommendation }>(state => getUserMedicalRecommendations(state), shallowEqual);
    const availableInventory = useSelector<State, any>(state => getCurrentDriverInventoryPeriodForDriverFromProps(state, { dsprDriverId: inventoryDriverId }), shallowEqual);
    const dsprDriver = useSelector<State, any>(state => getDSPRDriverWithUserFromProps(state, { dsprDriverId: dsprDriverIdFromOrder }), shallowEqual)
    const loggedInUser = useSelector<State, any>(getUserWithDSPRManagersAndDSPRDrivers, shallowEqual);
    const orderScans = useSelector<State, { [orderDetailId: number]: OrderScan[] }>(state => getOrderScansForOrderFromProps(state, { orderId }), shallowEqual);
    const currentFullMenuDriver = useSelector<State, DsprDriver | null>(state => getCurrentFullMenuDriverIdForDSPRFromProps(state, { dsprId }), shallowEqual);
    const [orderStatusButtonProps, setOrderStatusButtonProps] = useState(null);

    useEffect(() => {
        setOrderStatusButtonProps({
            disableExtraButtons,
            order,
            orderId,
            dspr,
            setDisableButtonCallBack,
            closeModal,
            setExtraOrderFunctionalityErrorCallBack,
            isDriverForOrder,
            isManagerForDSPR
        })
    }, [])


    useEffect(() => {
        if (loggedInUser) {
            setIsManagerForDSPR(loggedInUser.dsprManagers.filter(dsprManagerObject => dsprManagerObject.dspr === dsprId).length >= 1)
            setIsDriverForOrder(loggedInUser.dsprDrivers.filter(dsprDriverObject => (dsprDriverObject.dspr === dsprId) && (dsprDriverObject.id === dsprDriverId)).length >= 1)
        }
    }, [loggedInUser, dsprId, dsprDriverId])
    useEffect(() => {
        dispatch<any>(getAlreadyScannedForOrder(orderId));
    }, [orderScans])

    const setDisableButtonCallBack = (trueOrFalse: boolean) => {
        setDisableButtons(trueOrFalse)
    }
    const setExtraOrderFunctionalityErrorCallBack = (message: string) => {
        setExtraOrderFunctionalityError(message)
    }

    const getMedicalRecommendationDetails = () => {
        const medicalRecommendation = order && order.userMedicalRecommendation && medicalRecommendations[order.userMedicalRecommendation];
        return medicalRecommendation ? <ListItem>Medical ID: {medicalRecommendation.idNumber}</ListItem> : null;
    }

    const getIdentificationDocumentDetails = () => {
        const birthDate = idDocument && parseDate(idDocument.birthDate);
        return idDocument ? <Fragment>
            <ListItem>Identification Document: {idDocument.idNumber}</ListItem>
            <ListItem>Birth Date: &nbsp;
                {birthDate ? <span className="date">
                    {birthDate.toLocaleString("en-us", { month: "long" })} {birthDate.getDate()}, {birthDate.getFullYear()}
                </span> : <span>Not provided</span>}
            </ListItem>
        </Fragment> : null;
    }

    const orderDetailMap = {};
    order.orderDetails && order.orderDetails.map((detail) => orderDetailMap[detail.id] = detail);
    const originalOrderCreationDate = parseDate(order.originalOrderCreatedTime);
    const orderCreationDate = parseDate(order.createdTime);
    const orderCompletedDate = parseDate(order.lastStatusChangeTime);
    const date = parseDate(order.originalOrderCreatedTime);

    const remainingBalance = order && order.aeroPayPaymentStatus && order.aeroPayPaymentStatus === "completed" && order.aeroPayAmountPaid && order.cashTotal ? order.aeroPayAmountPaid - order.cashTotal : undefined;

    useEffect(() => {
        const getOrderDetails = () => {
            dispatch<any>(getOrderDetailsWithId(orderId))
                .then(response => {
                    if (!mounted.current) return;

                    if (response.type === GET_ORDER_DETAILS_WITH_ID_FAILURE) {
                        setError(response.error);
                    } else {
                        const orderWithDetails = response.response && response.response.entities && response.response.entities.orders && response.response.entities.orders[orderId];
                        if (orderWithDetails) {
                            setError('');
                            setOrder(orderWithDetails);
                        } else {
                            setError('An unexpected error happened when fetching the order details. Please try again.');
                        }
                    }
                    setIsLoading(false);
                });
        }

        setIsLoading(true);
        getOrderDetails();

    }, [dispatch, orderId, show, dsprDriverIdFromOrder]);

    useEffect(() => {
        if (orderFromProps) {
            setOrder(orderFromProps)
        }
    }, [orderFromProps])

    useEffect(() => () => mounted.current = false, []);

    return <Fragment>
        <CardHeader title="Order Details" />
        <CardContent className="order-detail-content">
            {isLoading ? <div className="spinner-container">
                <CircularProgress />
            </div> : (
                error ? <p>{error}</p> : (
                    <Fragment>
                        {(showTripTicketOnly && hideMeta) && <a href={getTripTicketUrl(order.id)}>Trip ticket</a>}
                        {!hideMeta && <Fragment>
                            <a href={getTripTicketUrl(order.id)}>Trip ticket</a>

                            {
                                (dsprDriver && !hideDriver) ? <p>Driver: {
                                    dspr.isFullMenuAvailable && currentFullMenuDriver && dsprDriver.id === currentFullMenuDriver.id ?
                                        <strong>Driver Not Assigned Yet</strong>
                                        :
                                        (dsprDriver && !hideDriver) && <Link to={`/dspr/${dsprId}/drivers/${dsprDriver.id}`}>{dsprDriver.user.firstName} {dsprDriver.user.lastName}</Link>

                                } </p> : undefined
                            }

                            {!hideNote && <UserNotes
                                createUserNote={(userId, note, dsprDriverId, dsprManagerId) => dispatch(createUserNote(userId, note, dsprDriverId, dsprManagerId))}
                                hideUserNote={(noteId) => dispatch(hideUserNote(noteId))}
                                unhideUserNote={(noteId) => dispatch(unhideUserNote(noteId))}
                                userId={user.id}
                                dsprManagerId={dsprManagerId}
                                dsprDriverId={dsprDriverId}
                                userNotes={userNotes}
                                refreshUser={() => dispatch(getSpecificUser(user.id))}
                            />}
                            {!hideSpecialInstructions && order.specialInstructions ? <p style={{ marginTop: 10 }}><strong>Special Instructions:</strong> {order.specialInstructions}</p> : null}
                        </Fragment>}
                        <List>
                            {!hideMeta && <Fragment>
                                {order.userFirstTimeOrderWithDSPR && <ListItem>FIRST TIME ORDER</ListItem>}
                                {getMedicalRecommendationDetails() ? <ListItem><b>Medical User</b></ListItem> : <ListItem><b>Adult User</b></ListItem>}
                                <ListItem>
                                    {originalOrderCreationDate && <span className="date">
                                        Order Created: {originalOrderCreationDate.toLocaleString("en-us", { month: "long" })} {originalOrderCreationDate.getDate()}, {originalOrderCreationDate.getFullYear()}, at {originalOrderCreationDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                    </span>}
                                </ListItem>
                                {order.modifiedOrder && <ListItem>
                                    {orderCreationDate && <span className="date">
                                        Last Modified: {orderCreationDate.toLocaleString("en-us", { month: "long" })} {orderCreationDate.getDate()}, {orderCreationDate.getFullYear()}, at {orderCreationDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                    </span>}
                                </ListItem>}
                                {order.orderStatus === "completed" && <ListItem>
                                    {orderCreationDate && <span className="date">
                                        Completed: {orderCompletedDate.toLocaleString("en-us", { month: "long" })} {orderCompletedDate.getDate()}, {orderCompletedDate.getFullYear()}, at {orderCompletedDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                    </span>}
                                </ListItem>}
                                {user && <ListItem>{(loggedInUser && loggedInUser.systemAdministrator) ? <Link to={`/user/${user.id}`}>{user.firstName} {user.lastName}</Link> : `${user.firstName} ${user.lastName}`}, {formatPhone(user.phoneNumber)}</ListItem>}
                                {getIdentificationDocumentDetails()}
                                {getMedicalRecommendationDetails()}
                                {address && <ListItem>{address.street}, {address.zipCode} {address.aptNumber ? ` - Apt #: ${address.aptNumber}` : ""}</ListItem>}
                                <Divider />
                            </Fragment>}

                            {order && order.calculatedOrderDetails && order.calculatedOrderDetails.map(detail =>
                                <OrderDetailListItem
                                    key={`${detail.product.id}-${(detail.unit || '0')}-${(detail.appliedCoupon && detail.appliedCoupon.code) || 'none'}`}
                                    calculatedOrderDetail={detail}
                                    orderDetail={orderDetailMap[detail.orderDetail.id]}
                                    orderScans={orderScans}
                                    order={order}
                                    dspr={dspr}
                                />)}
                            <Divider />
                            {coupons && coupons.length > 0 && <Fragment>
                                <ListItem className="coupon-flexbox">
                                    <ListItemText primary={(coupons.length === 1 ? 'Coupon Code:' : 'Coupon Codes:') + "\n"} />
                                    <ListItemText primary={coupons.map(couponFromList => <Chip key={couponFromList.code + "-" + couponFromList.id} label={couponFromList.code.toUpperCase()}></Chip>)} />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary='Discount' secondary={`$${order.discountTotal}`} />
                                </ListItem>
                            </Fragment>}
                            <ListItem>
                                <ListItemText primary='SubTotal' secondary={`$${(order.cashTotalPreDiscounts - order.discountTotal).toFixed(2)}`} />
                            </ListItem>
                            {order.orderTaxDetails.length !== 0 ? order.orderTaxDetails.slice(0).reverse().map(detail => (
                                <ListItem key={detail.name + detail.amount.toString()}>
                                    <ListItemText primary={`${detail.name} : ${detail.rate}%`} secondary={`$${detail.amount.toFixed(2)}`} />
                                </ListItem>)
                            ) : (<ListItem key={"Tax0"}>
                                <ListItemText primary={"Tax"} secondary={"$0.00"} />
                            </ListItem>)}
                            <ListItem>
                                <ListItemText primary='Delivery Fee' secondary={order.deliveryFee === 0 ? "free" : `$${order.deliveryFee.toFixed(2)}`} />
                            </ListItem>
                            <ListItem>
                                <ListItemText className='total-price' primary={`Total: $${order.cashTotal.toFixed(2)}`} />
                            </ListItem>
                            {remainingBalance !== undefined && order.aeroPayAmountPaid && <Fragment>
                                <ListItem>
                                    <ListItemText className='total-price' primary={`Paid Through AeroPay: $${order.aeroPayAmountPaid.toFixed(2)}`} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText className='total-price' style={Math.round(remainingBalance) < 0.01 ? { color: "green" } : { color: "red" }} primary={`Remaining Balance: $${(remainingBalance).toFixed(2)}`} />
                                </ListItem>
                            </Fragment>}

                            {order && order.userNotifiedOnAllotmentIssueTimestamp && <UserNotifiedAllotmentTime order={order} dspr={dspr} />}
                        </List>

                        <AllotmentButtons
                            {...orderStatusButtonProps}
                            userNotifiedOfAllotment={order && order.userNotifiedOnAllotmentIssueTimestamp}
                        />
                        {/*Packaged will always need to be scanned*/}
                        {/*scan can also occur in complete*/}
                        <ScanProductButtons
                            {...orderStatusButtonProps}
                            orderDetails={orderDetails}
                        />
                        <AssignDriverButtons
                            {...orderStatusButtonProps}
                            user={user}
                            onCallDrivers={onCallDrivers}
                            dsprDriverIdFromOrder={dsprDriverIdFromOrder}
                            currentFullMenuDriver={currentFullMenuDriver}
                            dspr={dspr}
                        />
                        <PackageButton
                            {...orderStatusButtonProps}
                        />
                        <QueueButtons
                            {...orderStatusButtonProps}
                            dsprDriverIdFromOrder={dsprDriverIdFromOrder}
                            currentFullMenuDriver={currentFullMenuDriver}
                        />
                        {/* <InRouteButtons
                                {...orderStatusButtonProps}
                            /> */}
                        <InProcessButton
                            {...orderStatusButtonProps}
                        />
                        <CompleteOrderButton
                            {...orderStatusButtonProps}
                        />


                        {show && <ModifyOrderForm {...modifyOrderProps} disableButton={disableExtraButtons} currentOrder={{ ...order, user }} availableInventory={availableInventory ? availableInventory.dsprDriverInventoryItems || [] : []} fullInventoryDriverId={(order && order.dsprDriver !== inventoryDriverId) ? inventoryDriverId : null} />}

                        {!hideAdditionalOrderFunctionality && isManagerForDSPR &&
                            <Fragment>
                                <UncancelOrderButton
                                    {...orderStatusButtonProps}
                                />
                                <UncompleteOrderButton
                                    {...orderStatusButtonProps}
                                />
                                {!isManagerForDSPR && !isDriverForOrder && <p>You are not a manager of the DSPR that this order is from. Therefore, additional actions are not available</p>}
                                {extraOrderFunctionalityError !== '' && <p className="error">{extraOrderFunctionalityError}</p>}
                            </Fragment>
                        }

                        <CancelOrderButtons
                            {...orderStatusButtonProps}
                            cancelOrderHandler={cancelOrderHandler}
                        />

                    </Fragment>
                )
            )}
        </CardContent>
    </Fragment>
}

export default React.memo(OrderWithDetailsAndPrices);