import React, { Fragment } from 'react';

import './AnalyticsDisplayStyles.scss';

import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TableRow from '@material-ui/core/TableRow';
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import { Box, Button } from "@material-ui/core";

//Imports needed to rebuild analytics
import { shallowEqual, useSelector } from "react-redux";
import { State, User } from "../store/reduxStoreState";
import { getLoggedInUser } from "../selectors/userSelectors";

interface AnalyticsDisplayProps {
    tableHeaderNames: string[];
    tableBody: any;
    tableClass?: string;
    chartData?: { title: string, chart: JSX.Element, chartLegend: JSX.Element }[];
    isLoading: boolean;
    handleReloadAnalytics: (number?) => any;
    filterTypes: { [key: string]: string };
    currentFilterType: string;
    handleFilterChange: (event: React.ChangeEvent<{ name?: string, value: any }>) => void;
    renderAnalyticsDetailDialog?: () => JSX.Element;
    drivers?: any;
    currentDriverId?: string;
    handleDriverChange?: (event: React.ChangeEvent<{ name?: string, value: any }>) => void;
    brands?: any;
    currentBrandId?: number | string;
    handleBrandChange?: (event: React.ChangeEvent<{ name?: string, value: any }>) => void;
    rebuildAnalytics?: (id?) => void;
    handleShowCustomOrderForm?: () => void;
    handleShowCustomAnalyticsForm?: () => void;
}

function AnalyticsDisplay(props: AnalyticsDisplayProps) {
    const {
        tableHeaderNames, tableBody, chartData, tableClass = 'analytics-table-container',
        handleDriverChange, drivers, currentDriverId, filterTypes, currentFilterType,
        handleFilterChange, isLoading, handleReloadAnalytics, renderAnalyticsDetailDialog,
        brands, currentBrandId, handleBrandChange, handleShowCustomOrderForm, handleShowCustomAnalyticsForm, rebuildAnalytics
    } = props;

    const loggedInUser = useSelector<State, User>(state => getLoggedInUser(state), shallowEqual);

    const renderTableHeaders = () => {
        return tableHeaderNames.map(name => <TableCell key={name}>{name}</TableCell>)
    }

    /**Render select form to choose time frame to filter data (days, months, quarters, years...) */
    const renderRangeSelect = () => {
        return (
            <FormControl>
                <Select value={currentFilterType} onChange={handleFilterChange}>
                    {Object.keys(filterTypes).map(key => (
                        <MenuItem value={filterTypes[key]} key={key}>
                            {key[0].toUpperCase() + key.slice(1).toLowerCase()}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }

    return (
        <Fragment>
            {isLoading ? <CircularProgress /> : <Fragment>
                <div className={'chart-container'}>
                    {chartData && chartData.length > 0 &&
                        <Grid container spacing={3}>
                            {chartData.map((chart, idx) => (
                                <Grid item xs={12} md={12} lg={4} key={idx}>
                                    <Card>
                                        <CardContent>
                                            <h4>{chart.title}</h4>
                                            {chart.chart}
                                            {chart.chartLegend}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    }
                </div>
                <Card>
                    <CardContent>
                        <Fragment>
                            <CardActions>
                                <Box display='flex' alignItems='flex-end' width={1}>
                                    {/*Date Range Selector*/}
                                    {renderRangeSelect()}

                                    {/*Driver Selector*/}
                                    {(drivers && handleDriverChange) ?
                                        <Box ml={3}>
                                            <FormControl>
                                                <InputLabel>Select a Driver</InputLabel>
                                                <Select value={currentDriverId} onChange={handleDriverChange}>
                                                    {drivers}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        : null
                                    }

                                    {/*Brand Selector*/}
                                    {(brands && handleBrandChange) ?
                                        <Box ml={3}>
                                            <FormControl>
                                                <InputLabel>Select a Brand</InputLabel>
                                                <Select value={currentBrandId} onChange={handleBrandChange}>
                                                    {brands}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        : null
                                    }
                                    <Box ml={3}>
                                        <Button variant="contained" color="primary" onClick={() => handleReloadAnalytics()}>Reload
                                            Analytics</Button>
                                    </Box>
                                    {(!brands && !drivers && handleShowCustomOrderForm) ? <Box ml={3}>
                                        <Button variant="contained" color="primary" onClick={() => handleShowCustomOrderForm()}>Create Order Report</Button>
                                    </Box> : null}

                                    {/*{(!brands && !drivers && handleShowCustomAnalyticsForm) ? <Box ml={3}>*/}
                                    {(
                                        brands && currentBrandId && !drivers ||
                                        !brands && !drivers &&
                                        handleShowCustomAnalyticsForm) ? <Box ml={3}>
                                        <Button variant="contained" color="primary" onClick={() => handleShowCustomAnalyticsForm()}>Create Analytics Report</Button>
                                    </Box> : null}


                                    {/*Rebuild Driver Analytics - Comment out when not using*/}
                                    {/*{loggedInUser*/}
                                    {/*&& (loggedInUser.id === 1 || loggedInUser.id === 18874 || loggedInUser.id === 41729)*/}
                                    {/*&& currentDriverId*/}
                                    {/*&& <Box ml={2}>*/}
                                    {/*    <Button variant="contained" color="primary"*/}
                                    {/*            onClick={() => rebuildAnalytics(currentDriverId)}>Rebuild Driver*/}
                                    {/*        Analytics</Button>*/}
                                    {/*</Box>*/}
                                    {/*}*/}

                                    {/*Rebuild Brand - Comment out when not using*/}

                                    {currentBrandId && loggedInUser
                                        && (loggedInUser.id === 1 || loggedInUser.id === 18874 || loggedInUser.id === 41729)
                                        && <Box ml={2}>
                                            <Button variant={'contained'} color={'primary'}
                                                onClick={() => rebuildAnalytics(currentBrandId)}>Rebuild Brand
                                                Analytics</Button>
                                        </Box>
                                    }


                                    {/*Rebuild DSPR - Comment out when not using*/}
                                    {/*{loggedInUser*/}
                                    {/*&& (loggedInUser.id === 1 || loggedInUser.id === 18874 || loggedInUser.id === 41729)*/}
                                    {/*&& !handleBrandChange && !handleDriverChange*/}
                                    {/*&& <Box ml={2}>*/}
                                    {/*    <Button variant={'contained'} color={'primary'} onClick={rebuildAnalytics}>*/}
                                    {/*        Rebuild DSPR Analytics</Button>*/}
                                    {/*</Box>*/}
                                    {/*}*/}

                                </Box>
                            </CardActions>
                            <div className={tableClass}>
                                {tableBody && tableBody.length > 0
                                    ? <Table>
                                        <TableHead>
                                            <TableRow>
                                                {renderTableHeaders()}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tableBody}
                                        </TableBody>
                                    </Table>
                                    : (!currentDriverId && handleDriverChange)
                                        ? <Box mt={2}><h4>Please select a driver</h4></Box>
                                        : (!currentBrandId && handleBrandChange)
                                            ? <Box mt={2}><h4>Please select a Brand</h4></Box>
                                            : <Box mt={2}><h4>No analytics data found for this selection</h4></Box>
                                }
                            </div>
                        </Fragment>
                        {renderAnalyticsDetailDialog && renderAnalyticsDetailDialog()}
                    </CardContent>
                </Card>
            </Fragment>}
        </Fragment>
    )
}

export default AnalyticsDisplay;